<template>
    <div id="ambulance-page">
        <div class="top-links">
            <div class="logo text-center d-sm-none d-block">
                <router-link to="/">
                    <img src="/img/logo_selcare_512.png" class="home-logo" alt="" loading="lazy">
                </router-link>
            </div>
        </div>
        <div class="banner-top">
            <img src="/img/ambulance_pic05.jpg" alt="">
        </div>
        <div class="row">
            <div class="col-md-6 col-12">
                <div class="text-side">
                    <div class="mb-4">
                        <div class="title">
                            ABOUT US
                        </div>
                        <div class="description">
                            <p>Selcare Ambulance is your reliable partner in times of crisis, dedicated to providing swift and compassionate service. 
                                As a vital extension of the Selcare suite of healthcare services, our ambulance and paramedic team stand at the forefront, 
                                reaching those in need with the highest level of medical care and dedication.</p>
                        </div>
                    </div>
                    <div class="mb-4">
                        <div class="title">
                            OUR VISION
                        </div>
                        <div class="description">
                            <p>Our vision is to be the driving force behind a safer, healthier society. We see a future where communities everywhere can 
                                rely on our fleet for swift, effective and compassionate response in times of need, contributing to a world where emergencies 
                                are met with unwavering care.</p>
                        </div>
                    </div>
                    <div class="mb-4">
                        <div class="title">
                            OUR MISSION
                        </div>
                        <div class="description">
                            <p>Our mission is to provide rapid, professional, and compassionate emergency medical services to individuals in their most vulnerable moments. 
                                We are dedicated to continuous improvement, innovation, and community education to enhance overall emergency response capabilities. We actively 
                                engage in comprehensive training programs, conduct rigorous quality assessments, and spearhead community initiatives. We provide a specialized 
                                medic team for events to ensure the safety of participants, offer hospital transfers, and provide prompt medical attention, solidifying our commitment 
                                to the well-being of the communities we serve.</p>
                        </div>
                    </div>
                    <div class="mb-4">
                        <div class="title">
                            Service Offerings
                        </div>
                        <div class="description">
                            <p>
                                <span style="font-weight: bold;">Ambulance Emergency Response</span> <br />
                                24 Hours Emergency response medical team that is ready to handle any medical emergency. 
                            </p>
                            <p>
                                <span style="font-weight: bold;">Ambulance Transfer</span> <br />
                                24 Hours transportation for emergency and non emergency cases medical interfacility and interstate transfer. 
                            </p>
                            <p>
                                <span style="font-weight: bold;">Ambulance Event Standby</span> <br />
                                For events requiring ambulance support for instance corporate events, sports, concerts etc. 
                            </p>
                        </div>
                    </div>
                    <div class="mb-4">
                        <div class="title">
                            How To Reach Us
                        </div>
                        <div class="description">
                            <p>You can contact us by dialing 1800-22-6600 or drop us a message on WhatsApp at 013-375 0160.</p>
                        </div>
                    </div>
                    <div class="mb-4">
                        <div class="title">
                            Booking Process for Non-Emergency Cases
                        </div>
                        <div class="description">
                            <p>Call the Selcare Hotline at 1800-22-6600 or the Ambulance Hotline Number at 013-375 0160.</p>
                            <p><a target="_blank" href="https://wa.link/nr7gb2">Or click here to fill the form and response to whatsapp.</a></p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-6 col-12">
                <div class="image-side">
                    <img src="/img/ambulance_pic03.png" alt="">
                    <img src="/img/ambulance_pic02.png" alt="">
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    
}
</script>

<style>
#ambulance-page {
    padding-top: 10px;
}

#ambulance-page .banner-top {
    width: 100%;
    height: 500px;
}
#ambulance-page .banner-top img {
    object-fit: cover;
    height: 100%;
    width: 100%;
}

#ambulance-page .text-side {
    padding: 20px 45px;
}
#ambulance-page .text-side .title {
    color: #a91a16;
    font-size: 24px;
    font-weight: 800;
}
#ambulance-page .text-side .description {
    text-align: justify;
    font-size: 17px;
}

#ambulance-page .image-side {
    padding: 20px 45px;
}
#ambulance-page .image-side img {
    width: 100%;
    margin-bottom: 20px;
}


/** Media Queries */
@media (max-width: 575px) {
    #ambulance-page {
        margin: 30px 0px;
    }
    #ambulance-page .banner-top {
        height: 210px;
    }

    .top-links {
        margin-bottom: 20px;
    }
    .top-links > a {
        float: left;
        margin-top: 10px;
    }
    .top-links .logo {
        margin-right: 20px;
    }
    .top-links .home-logo {
        height: 75px;
    }
}
</style>